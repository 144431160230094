import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Avatar,
} from "@nextui-org/react";

import { useCallback } from "react";
import { LuLogIn, LuLogOut, LuUser } from "react-icons/lu";
import { toast } from "react-hot-toast";
import { useUser } from "~/stores/user";
import supabase from "~/utils/supabase";

export default function UserMenu() {
  const { user, loginCheck, profile } = useUser();

  const logout = useCallback(async () => {
    try {
      const { error } = await supabase.auth.signOut();

      if (error) {
        throw error;
      }

      toast.success("Logged out successfully");
    } catch (error: unknown) {
      toast.error("Oops! Something went wrong.");
    }
  }, []);

  return (
    <Dropdown backdrop="blur" placement="bottom-end">
      <DropdownTrigger>
        <Avatar
          color={user && user?.id ? "primary" : "default"}
          isBordered
          as="button"
          alt="profile"
          radius="full"
          size="sm"
          name={
            profile?.name && profile.name.length > 0
              ? profile.name[0]
              : undefined
          }
          icon={<LuUser className="w-4 h-4" />}
        />
      </DropdownTrigger>
      {user && user?.id ? (
        <DropdownMenu aria-label="Profile Actions" variant="flat">
          <DropdownSection showDivider>
            <DropdownItem key="profile" isReadOnly textValue="profile">
              Signed in as <br /> {profile?.name ? profile.name : ""}{" "}
              {user.phone &&
                `(+${
                  user.phone.slice(0, 2) +
                  "*".repeat(user.phone.length - 5) +
                  user.phone.slice(-3)
                })`}
            </DropdownItem>
          </DropdownSection>
          <DropdownItem
            onPress={logout}
            key="logout"
            color="danger"
            startContent={<LuLogOut className="w-4 h-4" />}
          >
            Log Out
          </DropdownItem>
        </DropdownMenu>
      ) : (
        <DropdownMenu aria-label="Profile Actions" variant="flat">
          <DropdownItem
            onPress={() => {
              loginCheck(() => {});
            }}
            key="login"
            color="primary"
            startContent={<LuLogIn className="w-4 h-4" />}
          >
            Log In
          </DropdownItem>
        </DropdownMenu>
      )}
    </Dropdown>
  );
}