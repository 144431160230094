import { useCallback, useState, useEffect } from "react";
import { Button, ButtonProps } from "@nextui-org/button";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from "@nextui-org/modal";
import { LuHistory, LuLocate, LuMapPin, LuSearch } from "react-icons/lu";
import { Listbox, ListboxItem } from "@nextui-org/listbox";
import { Input } from "@nextui-org/input";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { useDebounce } from "use-debounce";

import { Spinner } from "@nextui-org/spinner";
import { useUser } from "~/stores/user";
import {
  geocodeByPlaceId,
  geocodeLocation,
  getAutocompletePredictions,
  Place,
} from "~/utils/location";
import toast from "react-hot-toast";
import { PUBLIC_GOOGLE_MAPS_API_KEY } from "~/utils/constants";

const libraries = ["places"];

export default function LocationPicker({
  buttonProps,
}: {
  buttonProps: ButtonProps & { labelClassName?: string };
}) {
  const { location, setLocation } = useUser();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [suggestions, setSuggestions] = useState<Place[]>([]);
  const [filterText, setFilterText] = useState("");
  const [debouncedFilterText] = useDebounce(filterText, 600);
  const [loading, setLoading] = useState(false);
  const [isDetecting, setIsDetecting] = useState(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: PUBLIC_GOOGLE_MAPS_API_KEY as string,
    libraries: libraries as Libraries,
  });

  const [recent_locations, setRecentLocations] = useState<UserLocation[]>([]);

  const handleDetectLocation = useCallback(async () => {
    try {
      setIsDetecting(true);
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) =>
          navigator.geolocation.getCurrentPosition(resolve, reject)
      );

      const { latitude, longitude } = position.coords;

      const locationData = await geocodeLocation(latitude, longitude);

      setLocation(locationData);
      onOpenChange();
    } catch (e) {
      toast.error("Failed to detect location");
    } finally {
      setIsDetecting(false);
    }
  }, [onOpenChange, setLocation]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (debouncedFilterText.length === 0) {
      setSuggestions([]);
      return;
    }
    setLoading(true);
    getAutocompletePredictions(debouncedFilterText)
      .then((predictions) => {
        setSuggestions(predictions);
      })
      .catch((e) => {
        toast.error("Unable to load location suggestions at the moment.", e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedFilterText, isLoaded]);

  const handleItemPress = useCallback(
    async (item: Place) => {
      try {
        const location = await geocodeByPlaceId(item.place_id);
        setLocation({
          label: item.label,
          lat: location.lat,
          lng: location.lng,
        });
        onOpenChange();
      } catch (e) {
        toast.error("Failed to fetch location");
      }
    },
    [onOpenChange, setLocation]
  );

  useEffect(() => {
    if (!isOpen) {
      setFilterText("");
      setSuggestions([]);
    }
    if (isOpen) {
      const recentLocation = localStorage.getItem("recent_locations");
      if (recentLocation) {
        setRecentLocations(JSON.parse(recentLocation));
      }
    }
  }, [isOpen]);

  return (
    <>
      <Button {...buttonProps} onClick={onOpen} isLoading={!location}>
        <span className={buttonProps.labelClassName}>
          {location?.label || ""}
        </span>
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        backdrop="blur"
      >
        <ModalContent>
          <ModalHeader>Select Location</ModalHeader>
          <ModalBody>
            <div className="w-full space-y-4">
              <Input
                fullWidth
                autoFocus
                size="lg"
                startContent={<LuSearch />}
                aria-label="Search for a location"
                placeholder="Bengaluru"
                value={filterText}
                onValueChange={(value: string) => setFilterText(value)}
                isClearable
                onClear={() => setFilterText("")}
                variant="bordered"
              />
              {debouncedFilterText.length === 0 && (
                <Button
                  variant="bordered"
                  color="primary"
                  fullWidth
                  isLoading={isDetecting}
                  startContent={<LuLocate />}
                  onPress={handleDetectLocation}
                >
                  Detect my location
                </Button>
              )}
            </div>
            <Listbox
              variant="flat"
              items={suggestions}
              emptyContent={
                loading ? (
                  <Spinner />
                ) : (
                  debouncedFilterText.length > 0 &&
                  suggestions.length === 0 && <p>No Results Found</p>
                )
              }
              aria-label="Location suggestions"
            >
              {suggestions.map((item) => (
                <ListboxItem
                  key={item.place_id}
                  aria-label={item.label}
                  className="py-3"
                  startContent={<LuMapPin className="w-4 h-4" />}
                  onPress={() => {
                    handleItemPress(item);
                  }}
                >
                  {item.label}
                </ListboxItem>
              ))}
            </Listbox>

            {suggestions.length === 0 &&
              recent_locations.length > 0 &&
              !isDetecting && (
                <Listbox
                  variant="flat"
                  items={recent_locations}
                  aria-label="Recent Locations"
                  topContent={<p>Recent Locations</p>}
                >
                  {recent_locations.map((item, index) => (
                    <ListboxItem
                      key={index}
                      aria-label={item.label}
                      className="py-3"
                      startContent={<LuHistory className="w-4 h-4" />}
                      onPress={() => {
                        setLocation(item);
                        onOpenChange();
                      }}
                    >
                      {item.label}
                    </ListboxItem>
                  ))}
                </Listbox>
              )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
